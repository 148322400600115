<template>
  <v-container fluid class="h-100 pb-0 pt-12 px-0 autocomplete">
    <div class="d-flex flex-column pa-4 autocomplete-wraper">
      <label class="text-uppercase font-weight-bold text-center py-3">{{
        $t("navbar.searchPlaceholder")
      }}</label>
      <v-combobox
        class="search-input"
        clearable
        filled
        solo
        dense
        rounded
        v-model="selected"
        ref="autocomplete"
        :items="getItems"
        :search-input.sync="search"
        no-filter
        :label="isListening ? $t('navbar.speechActive') : ''"
        :placeholder="isListening ? $t('navbar.speechActive') : ''"
        flat
        item-text="name"
        item-value="name"
        hide-details
        hide-no-data
        return-object
        type="search"
        @focus="onFocus"
        @blur="onBlur"
        @keydown.enter.native.prevent.stop="doSearch"
        :menu-props="{
          closeOnContentClick: true,
          contentClass: 'searches-menu'
        }"
      >
        <template v-slot:prepend-item>
          <category-block
            v-if="search"
            :target="searchBarCategory"
            position="position1"
            class="category-block category-block-1"
          />
        </template>
        <template v-slot:append-item>
          <category-block
            v-if="search"
            :target="searchBarCategory"
            position="position2"
            class="category-block category-block-2"
          />
        </template>
        <template v-slot:item="{ item }">
          <div class="d-flex w-100">
            <!-- <v-list-item-icon
            v-if="item.type == 'Categorie'"
            class="category-img"
          >
            <img :src="getImage(item)" onerror="this.src='/no-icon.png'" />
          </v-list-item-icon> -->
            <v-list-item-icon v-if="item.type == 'Ricerche'">
              <v-icon @click.prevent.stop="deleteWord(item)" small>
                $close
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content @click="doSearch(item)">
              <v-list-item-title :class="item.type">
                <text-highlight :queries="search ? search : '*'">
                  {{ item.name }}
                </text-highlight>
              </v-list-item-title>
              <v-list-item-subtitle v-if="item.type == 'Categorie'">
                <v-badge
                  inline
                  v-if="item.count"
                  :content="item.count"
                  color="secondary"
                >
                  Cerca&nbsp;<strong>{{ search }} </strong>&nbsp;in
                  {{ item.name }}
                </v-badge>
              </v-list-item-subtitle>
              <v-list-item-subtitle v-if="item.type == 'Marche'">
                Cerca <strong> {{ search }} </strong> con marca {{ item.name }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </div>
        </template>
        <template slot="append">
          <v-icon
            aria-label="Cerca"
            @click.prevent.stop="doSearch"
            color="default"
            >$search</v-icon
          >
        </template>
      </v-combobox>
      <Barcode
        v-if="isCordova"
        @input="doSearchQRCode"
        :formats="barcodeFormats"
      />
      <div
        v-if="scannerError"
        class="text-center warning--text warning lighten-3 px-2 py-3 mt-2 rounded font-weight-semibold"
      >
        {{ scannerError }}
      </div>
    </div>
  </v-container>
</template>
<style lang="scss">
.autocomplete {
  width: 100%;
  background-color: var(--v-grey-lighten1);
  .autocomplete-wraper {
    margin-top: 8vh;
  }
}
.search-input {
  border: 1px solid var(--v-grey-lighten2);
  // border-radius: 4px;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    max-width: 100%;
  }
  max-width: 600px;
  .v-input__control {
    display: inline;
    height: 100%;
    .v-input__slot {
      height: 100%;
      //background-color: var(--v-grey-lighten1) !important;
      font-weight: bold;
    }
  }
}
.searches-menu {
  &.v-menu__content {
    max-height: 80vh !important;
  }
  .v-list-item {
    .v-list-item__content {
      padding: 6px 10px;
    }
    .v-list-item__title {
      font-size: 0.9rem;
      &.link {
        font-weight: bold;
        color: var(--v-secondary-lighten1);
      }
    }
  }
  .v-subheader {
    font: normal normal bold 14px/44px $body-font-family;
    white-space: nowrap;
    &::before {
      content: "";
      display: block;
      width: 20px;
      height: 1px;
      background: var(--v-secondary-lighten1);
      margin-right: 8px;
    }
    &::after {
      content: "";
      display: block;
      max-width: 100%;
      width: 500px;
      height: 1px;
      background: var(--v-secondary-lighten1);
      margin-left: 8px;
    }
  }
}
</style>
<script>
import Barcode from "./Barcode";

import FastSearchMenu from "./FastSearchMenu.vue";

import ProductService from "~/service/productService";
import AnalyticsService from "~/service/analyticsService";

import { mapGetters, mapActions } from "vuex";

import debounce from "lodash/debounce";
import forEach from "lodash/forEach";

import LongPress from "vue-directive-long-press";
import CategoryService from "~/service/categoryService";
import cmService from "@/commons/service/cmService";

export default {
  components: { Barcode },
  data() {
    return {
      searchBarCategory: null,
      loading: false,
      isFocused: null,
      selected: null,
      search: null,
      isListening: false,
      items: [],
      menuProps: {
        closeOnContentClick: true,
        openOnClick: true
      },
      speechOptions: {
        language: "it-IT",
        matches: "5",
        prompt: "", // Android only
        showPopup: true, // Android only
        showPartial: false
      },
      barcodeFormats: "QR_CODE",
      scannerError: null
    };
  },
  // watch: {
  //   search(val, oldVal) {
  //     if (val !== oldVal && val && val.length > 3) {
  //       this.fetchSuggestion();
  //     }
  //   }
  // },
  computed: {
    ...mapGetters({
      commonSearch: "wordsSearched/commonSearch"
    }),
    getItems() {
      let result = this.search ? this.items : this.commonSearch;
      // result.unshift({
      //   type: "FastSearch",
      //   name: "Spesa veloce"
      // });
      return result;
    }
  },
  directives: {
    "long-press": LongPress
  },
  methods: {
    ...mapActions({
      saveWord: "wordsSearched/saveWord",
      deleteWord: "wordsSearched/deleteWord"
    }),
    getImage(item) {
      return "/img_layout/categories_icon/" + item.id + ".svg";
    },
    onFocus() {
      this.isFocused = true;
    },
    onBlur() {
      this.isFocused = false;
    },
    fetchSuggestion: debounce(async function() {
      let _this = this;
      this.loading = true;
      if (this.search) {
        let newItems = [];
        await ProductService.suggest(this.search.trim()).then(function(
          response
        ) {
          let prevType = "none";

          forEach(response, function(value) {
            if (value.type != prevType) {
              newItems.push({ header: value.type });
              prevType = value.type;
            }
            newItems.push(value);
          });
        });
        cmService
          .search(this.search.trim(), ["post", "recipe"], 1, 5)
          .then(function(response) {
            if (response.data.length > 0) {
              newItems.push({ header: "News e ricette" });
              forEach(response.data, function(value) {
                newItems.push({
                  name: value.title,
                  type: "News e ricette",
                  ...value
                });
              });
              newItems.push({
                type: "link",
                name: "Cerca tra news e ricette >"
              });
            }
          });
        _this.loading = false;
        _this.items = newItems;
      }
    }, 200),
    doSearch(item) {
      this.items = [];

      var currentQuery = {};
      if (item.type == "News e ricette") {
        let routeName;
        switch (item.subtype) {
          case "post":
            routeName = "NewsDetail";
            break;
          case "approfondimento":
            routeName = "ApprofondimentoDetail";
            break;
          case "video":
            routeName = "VideoDetail";
            break;
          case "recipe":
            routeName = "AppuntinoDetail";
            break;
          case "appuntino":
            routeName = "AppuntinoDetail";
            break;
          case "mixology":
            routeName = "MixologyDetail";
            break;
          default:
            routeName = "Page";
        }
        this.$router.push({
          name: routeName,
          params: { postName: item.slug }
        });
      } else if (item.type === "Suggerimenti") {
        //ho selezionato un nome
        currentQuery.q = item.name;
      } else if (item.type === "Categorie") {
        currentQuery.parent_category_id = item.id;
        currentQuery.filter = item.name;
        currentQuery.q = this.search;
      } else if (item.type === "Ricerche") {
        currentQuery.q = item.name;
      } else if (item.type === "Marche") {
        currentQuery.parent_vendor_id = item.id;
        currentQuery.filter = item.name;
        currentQuery.q = this.search;
      } else if (item.type === "Barcode") {
        currentQuery.barcode = item.name;
      } else if (item.type === "FastSearch") {
        currentQuery.q = "";
      } else if (item.type === "post") {
        currentQuery.q = this.search;
      } else if (item.type === "link") {
        currentQuery.q = this.search;
      } else if (this.search != null) {
        currentQuery.q = this.search;
        this.saveWord(currentQuery.q);
      }

      if (currentQuery.q || currentQuery.barcode) {
        AnalyticsService.search(this.search);
        //this.$store.dispatch("category/resetFilters");
        global.EventBus.$emit("resetFilters");
        this.$emit("submit", false);
        if (item.type == "post") {
          let routeName;
          switch (item.subtype) {
            case "post":
              routeName = "NewsDetail";
              break;
            case "approfondimento":
              routeName = "ApprofondimentoDetail";
              break;
            case "video":
              routeName = "VideoDetail";
              break;
            case "recipe":
              routeName = "AppuntinoDetail";
              break;
            case "appuntino":
              routeName = "AppuntinoDetail";
              break;
            case "mixology":
              routeName = "MixologyDetail";
              break;
            default:
              routeName = "Page";
          }
          this.$router.push({
            name: routeName,
            params: { postName: item.slug }
          });
        } else if (item.type == "link") {
          this.$router.push({
            name: "WpSearch",
            query: currentQuery
          });
        } else {
          this.$router.push({
            path: "/search",
            name: "Search",
            query: currentQuery
          });
        }

        this.$nextTick(() => {
          this.$refs.autocomplete.blur();
          this.search = currentQuery.q;
        });
      }
    },
    doSearchQRCode(item) {
      this.scannerError = null;
      if (item.startsWith(window.location.origin)) {
        let path = item.slice(window.location.origin.length);
        if (path.startsWith("/#")) {
          path = path.substring(2);
        }
        this.$emit("submit", false);
        this.$router.push(path);
      } else if (this.checkIfUrl(item)) {
        this.$emit("submit", false);
        this.openExternal(item);
      } else {
        this.scannerError = this.$t("errors.errorCodeNotValid");
      }
    },
    checkIfUrl(str) {
      try {
        new URL(str);
        return true;
      } catch (error) {
        return false;
      }
    },
    openExternal(url) {
      if (this.$platform_is_cordova && url.indexOf("http") > -1) {
        window.cordova.InAppBrowser.open(url, "_system");
      } else {
        window.open(url, "_blank");
      }
    },
    onLongPressStart() {
      // triggers after 300ms of mousedown
      this.isListening = true;
      this.startSpeechRecognition();
    },
    onLongPressStop() {
      // triggers on mouseup of document
      this.isListening = false;
      this.stopSpeechRecognition();
    },
    startSpeechRecognition() {
      // Verify if recognition is available
      var _this = this;
      // eslint-disable-next-line no-undef
      // let platform = device ? device.platform.toLowerCase() : "ios";
      window.plugins.speechRecognition.isRecognitionAvailable(
        function(available) {
          if (!available) {
            console.log("Riconoscimento Vocale non disponibile");
          }

          // Check if has permission to use the microphone
          window.plugins.speechRecognition.hasPermission(
            function(isGranted) {
              if (isGranted) {
                window.plugins.speechRecognition.startListening(
                  function(result) {
                    // Show results in the console
                    console.log(result);
                    _this.search = result[0];
                    _this.doSearch(result[0]);
                  },
                  function(err) {
                    console.error(err);
                  },
                  _this.speechOptions
                );
              } else {
                // Request the permission
                window.plugins.speechRecognition.requestPermission(
                  function() {
                    // Request accepted, start recognition
                    window.plugins.speechRecognition.startListening(
                      function(result) {
                        // Show results in the console
                        console.log(result);
                        _this.search = result[0];

                        _this.doSearch(result[0]);
                      },
                      function(err) {
                        console.error(err);
                      },
                      _this.speechOptions
                    );
                  },
                  function(err) {
                    console.log(err);
                  }
                );
              }
            },
            function(err) {
              console.log(err);
            }
          );
        },
        function(err) {
          console.log(err);
        }
      );
    },
    openFastSearchDialog() {
      this.$emit("closeSearchModal", true);
      var defaultConfig = {
        toolbarColor: "secondary",
        fullscreen: this.$vuetify.breakpoint.smAndDown
      };
      let properties = {
        waitForResult: true,
        width: 650
      };
      var config = Object.assign({}, properties, defaultConfig);
      this.dialogInstance = this.$dialog.show(FastSearchMenu, config);
    },
    stopSpeechRecognition() {
      window.plugins.speechRecognition.stopListening(
        function() {
          // No more recognition
        },
        function(err) {
          console.log(err);
        }
      );
    },
    async fetchSearchBarCategory() {
      try {
        let result = await CategoryService.getCategoryBySlug("search-bar");
        this.searchBarCategory = result;
      } catch (err) {
        console.log(err);
      }
    }
  },
  mounted() {
    //this.fetchSearchBarCategory();
    global.EventBus.$on("clearSearch", () => {
      this.search = null;
      this.selected = null;
    });
    this.$nextTick(() => {
      // Use $refs to access the v-input element by its ref name
      this.$refs.autocomplete.focus();
    });
  },
  beforeDestroy() {
    global.EventBus.$off("clearSearch");
  }
};
</script>
