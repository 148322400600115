import get from "lodash/get";
import join from "lodash/join";
import compact from "lodash/compact";
import Popup from "@/components/Popup.vue";

function checkPopup(category) {
  try {
    let popupConfig = get(category, "metaData.category_popup");
    if (popupConfig?.ACTIVE) {
      let popupMap = global.vm.$store.getters["app/getPopupMap"];
      let canOpenPopup = false;

      if (typeof popupConfig.REOPENING_DAYS_DELAY == "number") {
        let lastTimeOpen =
          popupMap[category.categoryId + "" + popupConfig.TITLE];
        let today = new Date().getTime();
        if (
          !lastTimeOpen ||
          lastTimeOpen +
            popupConfig.REOPENING_DAYS_DELAY * 24 * 60 * 60 * 1000 <
            today
        ) {
          canOpenPopup = true;
        }
      } else {
        canOpenPopup = true;
      }

      if (canOpenPopup) {
        setTimeout(async function() {
          // let Popup = () => import("@/components/Popup.vue");

          await global.vm.$dialog.show(Popup, {
            waitForResult: true,
            // fullscreen: global.vm.$vuetify.breakpoint.xsOnly,
            width: 700,
            popup: popupConfig,
            categoryId: category.categoryId
          });
        }, popupConfig.OPENING_DELAY * 1000);
      }
    }
  } catch (err) {
    console.log(err);
  }
}
function getFullName(product) {
  return join(
    compact([product.name, product.shortDescr, product.description]),
    " "
  );
}
export { checkPopup, getFullName };
