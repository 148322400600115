<template>
  <v-row no-gutters class="grey lighten-2" align="center">
    <v-col cols="1" class="d-flex justify-start mx-2">
      <div class="pa-1 white rounded-sm">
        <v-img
          eager
          :src="serviceImg"
          height="45"
          width="45"
          contain
          position="center"
        />
      </div>
    </v-col>
    <v-col cols="10" class="d-flex align-center">
      <div class="d-flex flex-column justify-start w-100">
        <div class="info-text" @click="openAddressSelector">
          <span class="font-weight-bold">{{ serviceName }}</span>
          {{ shippingAddress }}<v-icon small right>$edit</v-icon>
        </div>
        <div
          class="info-text"
          :disabled="!editableTimeslot"
          @click="openTimeslotSelector"
        >
          <span class="font-weight-bold">{{
            $t("cart.cartInfo.timeslot")
          }}</span>
          {{ timeslot }}<v-icon small right>$edit</v-icon>
        </div>
      </div>
    </v-col>
  </v-row>
</template>
<style lang="scss" scoped>
.info-text {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.right-border {
  border-right: 1px solid $secondary;
}
</style>
<script>
import { mapState } from "vuex";
import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
export default {
  name: "MiniCartInfoList",
  mixins: [login, cartInfo],
  data() {
    return {
      maxWidth: 320,
      maxHeight: 60
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    })
  }
};
</script>
