<template>
  <div class="cart-item">
    <v-list-item
      :to="{
        name: 'Product',
        params: { slug: item.product.slug }
      }"
      :class="isCheckout ? 'py-3 is-checkout' : 'py-2 is-not-checkout'"
    >
      <v-row class="w-100 my-2 my-sm-0" align="center" justify="space-between">
        <v-col cols="12" :sm="isCheckout ? 5 : 6" lg="6" class="pa-0">
          <div class="d-flex">
            <v-list-item-avatar
              :width="full ? '70' : '70'"
              :height="full ? '70' : '70'"
              rounded="0"
              class="my-0"
            >
              <img
                :src="item.product.mediaURL"
                :alt="item.product.name"
                onerror="this.onerror=null;this.src='/no-icon.png'"
              />
            </v-list-item-avatar>

            <v-list-item-content class="default--text pa-0 pa-sm-2">
              <div class="font-weight-bold text-body-2">
                {{ item.product.name }}
              </div>
              <v-list-item-subtitle class="text-uppercase text-body-2">
                {{ item.product.shortDescr }}
              </v-list-item-subtitle>
              <v-list-item-subtitle
                v-if="item.cartItemInfo.size"
                class="cart-item-info"
              >
                <i>Preferenza: {{ item.cartItemInfo.size }}</i>
              </v-list-item-subtitle>

              <v-list-item-subtitle
                v-if="
                  itemAdjustment &&
                    itemAdjustment.userGiftCertificateId &&
                    itemAdjustment.giftCertificate
                "
                class="cart-item-info promo--text"
              >
                <em>{{ itemAdjustment.giftCertificate.name }}: </em>
                <!-- -{{ $n(itemAdjustment.valueVariation, "currency") }} -->
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action
              v-if="
                $vuetify.breakpoint.xsOnly &&
                  !(
                    item.product.productInfos &&
                    item.product.productInfos.USER_DISABLED == '1'
                  )
              "
            >
              <v-btn
                icon
                aria-label="Rimuovi dal carrello"
                @click.prevent="remove"
                @mousedown.stop
                color="primary"
              >
                <v-icon color="secondary">$delete</v-icon>
              </v-btn>
            </v-list-item-action>
          </div>
        </v-col>
        <v-col cols="12" :sm="isCheckout ? 7 : 6" lg="6" class="pa-0">
          <v-list-item-action class="mr-0 w-100 mt-3 mt-sm-0 mb-0">
            <div
              class="d-flex align-center w-100"
              :class="
                isCheckout || $vuetify.breakpoint.xs
                  ? 'justify-space-between'
                  : 'justify-end'
              "
            >
              <div class="d-flex flex-column cart-item-price" v-if="isCheckout">
                <div class="d-flex justify-space-between align-end">
                  <div class="cur-price">
                    {{ $n(item.product.priceDisplay, "currency")
                    }}<span
                      v-if="item.product.productInfos.TIPOLOGIA != 'Pezzo'"
                      class="unit-price"
                      >/{{ unit }}
                    </span>
                  </div>
                </div>
                <span class="text-right w-100 iva">{{
                  item.product.ivaCategory.descr
                }}</span>
              </div>
              <ProductQty
                v-if="!showQntyInput"
                :item="item"
                :product="item.product"
                :showCartButton="showCartButton"
                :updateCart="true"
              />
              <div class="product-qty" v-else>
                <div
                  class="qnty-manual-input qty-wrap d-flex align-center rounded-md"
                  :class="{ 'not-empty': quantity > 0 }"
                  transition="fab-transition"
                >
                  <a
                    role="button"
                    class="close px-2"
                    @click.stop.prevent="toggleShowInput(false)"
                    @mousedown.stop
                  >
                    <v-icon>$delete</v-icon>
                  </a>
                  <v-tooltip
                    bottom
                    :open-on-hover="false"
                    :open-on-click="true"
                    max-width="500"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="qntyInput"
                        ref="manualInput"
                        class="manual-input"
                        hide-details
                        autofocus
                        outlined
                        dense
                        v-mask="'###'"
                        type="number"
                        @click.stop.prevent=""
                        @keyup.enter="toggleShowInput(true, null, true)"
                        @keyup.esc="toggleShowInput(false)"
                        @mousedown.stop
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <span
                      class="qntity-tooltip"
                      v-if="
                        this.product.productInfos.QTY_LOCKED != 'true' ||
                          (this.product.productInfos.QTY_LOCKED == 'true' &&
                            !modulus(qntyInput))
                      "
                      v-html="
                        $t('product.quantity.confirmQntity', {
                          qntyInput: qntyInput
                        })
                      "
                    >
                    </span>
                    <span
                      class="qntity-tooltip"
                      v-if="
                        this.product.productInfos.QTY_LOCKED == 'true' &&
                          modulus(qntyInput) &&
                          parseInt(qntyInput) <=
                            parseInt(this.product.productInfos.QTY_MULT)
                      "
                      v-html="
                        $t('product.quantity.editQntityMin', {
                          lowerInput: lowerInput
                        })
                      "
                    >
                    </span>

                    <span
                      class="qntity-tooltip"
                      v-if="
                        this.product.productInfos.QTY_LOCKED == 'true' &&
                          modulus(qntyInput) &&
                          parseInt(qntyInput) >
                            parseInt(this.product.productInfos.QTY_MULT)
                      "
                      v-html="
                        $t('product.quantity.editQntityMinMax', {
                          lowerInput: lowerInput,
                          higherInput: higherInput
                        })
                      "
                    >
                    </span>
                  </v-tooltip>
                  <a
                    role="button"
                    class="check px-2"
                    @click.stop.prevent="toggleShowInput(true, null, true)"
                    @mousedown.stop
                  >
                    <v-icon>$success</v-icon>
                  </a>
                </div>
              </div>

              <div class="price font-weight-bold ml-2">
                {{ $n(item.grossTotal, "currency") }}
              </div>
              <v-btn
                icon
                aria-label="Rimuovi dal carrello"
                @click.prevent="remove"
                @mousedown.stop
                color="primary"
                v-if="
                  $vuetify.breakpoint.smAndUp &&
                    !(
                      item.product.productInfos &&
                      item.product.productInfos.USER_DISABLED == '1'
                    )
                "
              >
                <v-icon color="secondary">$delete</v-icon>
              </v-btn>
            </div>
          </v-list-item-action>
        </v-col>
      </v-row>
    </v-list-item>
    <v-checkbox
      v-if="isCheckout"
      dense
      color="primary"
      hide-details
      class="accept-alternatives my-1 pt-0"
      v-model="acceptAlternative"
    >
      <template v-slot:label>
        <span>{{ $t("cartDetail.acceptAlternatives") }}</span>
      </template>
    </v-checkbox>

    <v-textarea
      v-if="isCheckout"
      ref="note"
      :label="$t('cartDetail.note')"
      auto-grow
      dense
      solo
      flat
      hide-details="auto"
      rows="1"
      row-height="15"
      prepend-inner-icon="$edit"
      v-model="userNotes"
      :rules="notesRule"
      @blur="updateNote(item, 'user_notes', tempUserNotes)"
      class="note-textarea"
    ></v-textarea>
    <v-divider></v-divider>
    <v-dialog v-model="removeDialog" max-width="400">
      <!-- <div class="vuedl-layout__closeBtn" @click="removeDialog = false">
        ×
      </div> -->
      <v-card>
        <v-card-text class="body-1 pt-8">
          {{
            $t("message.remove", {
              name: this.product.name,
              quantity: this.quantity,
              unit: this.unit
            })
          }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn outlined text @click="removeDialog = false">
            No
          </v-btn>

          <v-btn
            class="primary white--text"
            text
            @click.prevent="doRemove"
            @mousedown.stop
          >
            Si
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<style lang="scss">
.cart-item {
  .product-promo-price {
    min-width: 140px;
    .cur-price {
      text-align: center;
    }
  }
  .price {
    min-width: 60px;
  }
  .is-not-checkout {
    .price {
      font-size: 18px;
    }
  }

  .product-price {
    min-width: 140px;
    text-align: center;
    @media (max-width: 320px) {
      min-width: auto;
    }
  }
  .gross-total {
    width: auto !important;
    min-width: 70px;
    text-align: right;
    @media #{map-get($display-breakpoints, 'xs-only')} {
      min-width: auto !important;
      width: 25%;
      font-size: 18px !important;
    }
  }
  .product-qty {
    width: auto;
  }
  .cart-item-price {
    .cur-price {
      font-size: 18px;
    }
    .iva {
      font-size: 12px;
    }
  }
}
.is-cordova {
  .cart-item {
    .product-qty {
      margin: 0px 8px;
    }
  }
}
</style>
<script>
import ProductQty from "@/components/product/ProductQty.vue";
import productMixin from "~/mixins/product";
import get from "lodash/get";

import { mapActions, mapGetters } from "vuex";
import LongPress from "vue-directive-long-press";
import { mask } from "vue-the-mask";

import {
  isOnlyCharacterAndNumber,
  maxLength
} from "~/validator/validationRules";

export default {
  name: "CartItem",
  directives: {
    "long-press": LongPress,
    mask: mask
  },
  mixins: [productMixin],
  components: {
    ProductQty
  },
  props: {
    full: { type: Boolean, default: false },
    isCheckout: { type: Boolean, default: false },
    showCartButton: { type: Boolean, default: false },
    item: { type: Object, required: true }
  },
  data() {
    return {
      removeDialog: false,
      notesRule: [
        isOnlyCharacterAndNumber(
          "Sono ammessi solamente caratteri alfanumerici."
        ),
        maxLength(200, "max 200 caratteri")
      ]
    };
  },
  computed: {
    product() {
      return this.item.product;
    },
    classCardContainerObject: function() {
      if (this.isCheckout && this.$vuetify.breakpoint.mdAndUp) {
        return "isCheckout d-flex flex-column justify-space-around";
      } else {
        if (!this.$vuetify.breakpoint.xs) {
          return "d-flex flex-row justify-space-around";
        }
        return "XS";
      }
    },
    itemAdjustment() {
      return this.getOrderAdjustmentByItemId(this.item.itemId);
    },
    ...mapGetters({
      getOrderAdjustmentByItemId: "cart/getOrderAdjustmentByItemId"
    }),
    acceptAlternative: {
      get() {
        return (
          get(this.item, "cartItemInfo.accept_alternatives", "true") == "true"
        );
      },
      set(value) {
        this.setCartItemInfo({
          items: this.item,
          name: "accept_alternatives",
          value: "" + value
        });
      }
    },
    userNotes: {
      get() {
        return this.item.cartItemInfo.user_notes;
      },
      set(value) {
        this.tempUserNotes = value;
      }
    }
  },
  methods: {
    ...mapActions({
      setCartItemInfo: "cart/setCartItemInfo"
    }),
    remove() {
      this.removeDialog = true;
    },
    doRemove() {
      this.removeDialog = false;
      this.removeItem(this.item);
    },
    updateNote(item, name, value) {
      if (this.$refs.note.validate() && value != null) {
        this.setCartItemInfo({
          items: item,
          name: name,
          value: "" + value
        });
      }
    }
  }
};
</script>
