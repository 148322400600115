import store from "@/store";
let routes = [
  {
    path: "/reset-password-request",
    name: "ResetPasswordRequest",
    component: () => import("@/views/profile/ResetPasswordRequest.vue"),
    meta: {
      slug: "reset-password-request"
    }
  },
  {
    path: "/registration/reset-password-confirm",
    name: "ResetPasswordConfirm",
    props: route => ({
      message: route.params.message,
      login: route.params.login || route.query?.login,
      queryToken: route.query?.token
    }),
    component: () => import("@/views/profile/ResetPasswordConfirm.vue"),
    meta: { slug: "reset-password" }
  },

  {
    path: "/reset-password-sent",
    name: "ResetPasswordSent",
    props: { showLogin: true },
    component: () => import("@/views/EbsnCategory.vue"),
    meta: { slug: "reset-password-sent" }
  },
  {
    path: "/dashboard/reset-password",
    name: "ResetPassword",
    component: () => import("@/views/profile/ResetPassword.vue"),
    meta: { slug: "reset-password" }
  },
  {
    path: "/reset-password-completed",
    name: "ResetPasswordCompleted",
    component: () => import("@/views/EbsnCategory.vue"),
    props: { showLogin: true },
    meta: { slug: "reset-password-completed" }
  },
  {
    path: "/registration-user",
    name: "RegistrationUser",
    component: () => import("@/views/profile/RegistrationUser.vue"),
    meta: { slug: "registration-user" }
  },
  {
    path: "/registration-card",
    name: "RegistrationCard",
    component: () => import("@/views/profile/RegistrationForms.vue"),
    props: {
      type: "OldUser",
      isRegisterWithCard: true
    },
    meta: { slug: "registration-card" }
  },
  {
    path: "/registration-nocard",
    name: "RegistrationNoCard",
    component: () => import("@/views/profile/RegistrationForms.vue"),
    props: {
      type: "NewUser",
      isRegisterWithCard: false
    },
    meta: { slug: "registration-nocard" }
  },
  {
    path: "/thankyou-agreement",
    name: "RegistrationCompletedAgreement",
    component: () => import("@/views/profile/RegistrationCompleted.vue"),
    props: {
      mail: null,
      agreement: true
    },
    meta: { slug: "registration-confirm-agreement" }
  },
  // {
  //   path: "/registration/confirm",
  //   name: "RegistrationCompleted",
  //   component: () => import("@/views/EbsnCategory.vue"),
  //   meta: { slug: ["registration-requested"] }
  // },
  {
    path: "/registration/welcome",
    name: "RegistrationCompletedClient",
    component: () => import("@/views/EbsnCategory.vue"),
    meta: { slug: ["registration-welcome"] }
  },
  {
    path: "/welcome",
    name: "RegistrationCompleted",
    component: () => import("@/views/profile/RegistrationCompleted.vue"),
    meta: { slug: ["registration-welcome"] }
  },
  {
    path: "/registration-social-completed",
    name: "RegistrationSocialCompleted",
    component: () => import("@/views/EbsnCategory.vue"),
    meta: { slug: "registration-social-completed" }
  },
  {
    path: "/registration/card-agreement",
    name: "CardAgreement",
    component: () => import("@/views/profile/CardAgreement.vue"),
    meta: { slug: ["registration/card-agreement", "card-agreement"] }
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    meta: { breadCrumb: "Menu utente", slug: "dashboard" },
    component: () => import("@/views/profile/Dashboard.vue")
  },
  {
    path: "/dashboard/getMorePoints",
    name: "GetMorePoints",
    meta: { breadCrumb: "Ottieni più punti", slug: "get-more-points" },
    component: () => import("@/views/profile/GetMorePoints.vue")
  },
  {
    path: "/dashboard/donatePoints",
    name: "GetMorePoints",
    meta: { breadCrumb: "Regala i tuoi punti", slug: "donate-points" },
    component: () => import("@/views/profile/DonatePoints.vue")
  },
  {
    path: "/dashboard/myFavorites",
    name: "MyFavorites",
    meta: { breadCrumb: "I miei preferiti", slug: "my-favorites" },
    component: () => import("@/views/profile/MyFavorites.vue")
  },
  {
    path: "/dashboard/profile",
    name: "ProfileUpdate",
    meta: { breadCrumb: "I miei dati", slug: "profile" },
    component: () => import("@/views/profile/ProfileUpdate.vue")
  },
  {
    path: "/dashboard/privacy",
    name: "ProfilePrivacy",
    meta: { breadCrumb: "Privacy e canali di contatto", slug: "privacy" },
    component: () => import("@/views/profile/ProfilePrivacy.vue")
  },
  {
    path: "/dashboard/receipts",
    meta: { breadCrumb: "I miei scontrini" },
    component: {
      render(c) {
        return c("router-view");
      }
    },
    beforeEnter(to, from, next) {
      global.EventBus.$emit("resetFilters");
      next();
    },
    children: [
      {
        name: "Receipts",
        path: "",
        meta: {
          requiresAuth: true,
          slug: ["receipts"]
        },
        component: () => import("@/views/profile/Receipts.vue"),
        slug: ["receipts"]
      },
      {
        name: "Receipt",
        path: ":index",
        meta: {
          requiresAuth: true,
          meta: ["receipts"],
          slug: ["receipts"]
        },
        component: () => import("@/views/profile/ReceiptDetails.vue")
      }
    ]
  },
  {
    path: "/profile",
    name: "Profile",
    meta: {
      requiresAuth: true,
      breadCrumb: "Area personale",
      slug: "dashboard"
    },
    component: () => import("@/views/profile/Profile.vue"),
    children: [
      {
        path: "gifts",
        name: "ProfileGift",
        meta: {
          requiresAuth: true,
          breadCrumb: "Buoni sconto",
          slug: "profile"
        },
        component: () => import("@/views/profile/ProfileGift.vue")
      },
      {
        path: "profile-summary",
        name: "ProfileSummary",
        meta: {
          requiresAuth: true,
          breadCrumb: "Il mio profilo",
          showTcoMenu: true,
          slug: "profile"
        },
        component: () => import("@/views/profile/ProfileSummary.vue")
      },
      {
        path: "profile-update",
        name: "ProfileUpdate",
        props: route => ({
          emailchanged: route.query.emailchanged
        }),
        meta: {
          requiresAuth: true,
          breadCrumb: "Il mio profilo",
          slug: ["profile"]
        },
        component: () => import("@/views/profile/ProfileUpdate.vue")
      },
      {
        path: "profile-update-error",
        name: "ProfileUpdateError",
        meta: {
          requiresAuth: true,
          breadCrumb: "I miei dati",
          slug: ["profile-error", "profile/profile-update-error"]
        },
        component: () => import("@/views/profile/ProfileUpdateError.vue")
      },
      {
        path: "changepassword",
        name: "UpdatePassword",
        meta: {
          requiresAuth: true,
          breadCrumb: "Aggiorna password",
          slug: ["change-password", "profile/changepassword"]
        },
        component: () => import("@/views/profile/UpdatePassword.vue")
      },
      {
        path: "profile-confirmed",
        name: "ProfileConfirmed",
        component: () => import("@/views/profile/ProfileConfirmed.vue"),
        meta: {
          requiresAuth: true,
          breadCrumb: "Aggiorna password",
          slug: ["profile-confirmed", "profile/profile-confirmed"]
        }
      },
      {
        path: "profile-updated",
        name: "ProfileUpdated",
        component: () => import("@/views/EbsnCategory.vue"),
        meta: {
          requiresAuth: true,
          breadCrumb: "Aggiorna password",
          slug: ["profile-updated", "profile/profile-updated"]
        }
      },
      {
        path: "email-updated",
        name: "EmailUpdated",
        component: () => import("@/views/EbsnCategory.vue"),
        meta: { slug: ["profile/email-updated", "email-updated"] }
      },
      {
        path: "invite",
        name: "Invite",
        meta: {
          requiresAuth: true,
          breadCrumb: "Invita i tuoi amici",
          slug: ["profile/invite", "invite"]
        },
        component: () => import("@/views/profile/Invite.vue")
      },
      {
        path: "news-list",
        name: "NewsListPage",
        meta: {
          requiresAuth: true,
          breadCrumb: "Archivio Messaggi",
          slug: ["archivio-messaggi"]
        },
        component: () => import("@/views/profile/NewsList.vue")
      },
      {
        path: "orders",
        meta: {
          requiresAuth: true,
          breadCrumb: "I miei ordini"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        beforeEnter(to, from, next) {
          global.EventBus.$emit("resetFilters");
          next();
        },
        children: [
          {
            name: "Orders",
            path: "",
            meta: {
              requiresAuth: true,
              slug: ["orders"]
            },
            component: () => import("@/views/profile/Orders.vue"),
            slug: ["profile/orders", "orders"]
          },
          {
            name: "Order",
            path: ":orderId",
            meta: {
              requiresAuth: true,
              breadCrumb: "Dettaglio ordine",
              meta: ["order", "profile/orders/order"]
            },
            component: () => import("@/views/profile/Order.vue")
          }
        ]
      },
      {
        path: "lists",
        meta: {
          requiresAuth: true,
          breadCrumb: "Le tue liste"
        },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        beforeEnter(to, from, next) {
          global.EventBus.$emit("resetFilters");
          next();
        },
        children: [
          {
            name: "Lists",
            path: "",
            meta: {
              requiresAuth: true,
              meta: ["profile/lists", "lists"],
              slug: ["lists"]
            },
            component: () => import("@/views/profile/Lists.vue")
          },
          {
            path: ":listId",
            name: "List",
            meta: {
              requiresAuth: true,
              breadCrumb: "Dettaglio lista"
            },
            component: () => import("@/views/profile/List.vue")
          }
        ]
      },
      {
        path: "preferred",
        name: "Favourites",
        meta: {
          requiresAuth: true,
          breadCrumb:
            store && store.getters["cart/hasFavorites"]
              ? "I miei preferiti"
              : "I più acquistati"
        },
        component: () => import("@/views/profile/Favourites.vue")
      },
      {
        path: "addresses",
        meta: { requiresAuth: true, breadCrumb: "I miei indirizzi" },
        component: {
          render(c) {
            return c("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "Addresses",
            meta: {
              requiresAuth: true,
              slug: ["profile/addresses", "addresses"]
            },
            component: () => import("@/views/profile/ProfileAddresses.vue")
          },
          {
            path: "manage/:addressId?",
            name: "EditAddress",

            meta: { requiresAuth: true, breadCrumb: "Dettaglio indirizzo" },
            component: () => import("@/views/profile/EditAddress.vue")
          }
        ]
      },
      {
        path: "payment-method",
        name: "MyPaymentMethods",
        meta: {
          requiresAuth: true,
          breadCrumb: "Le mie carte",
          slug: ["profile/payment-method", "payment-method"]
        },
        component: () => import("@/views/profile/MyPaymentMethods.vue")
      },
      {
        path: "last-purchase",
        name: "LastPurchase",
        meta: {
          requiresAuth: true,
          breadCrumb: "Ultima spesa",
          slug: ["profile/last-purchase", "last-purchase"]
        },
        component: () => import("@/views/profile/LastPurchase.vue")
      },
      {
        path: "rewards-cart",
        name: "RewardsCart",
        meta: { requiresAuth: true, breadCrumb: "Carrello premi" },
        component: () => import("@/views/profile/RewardsCart.vue")
      },
      {
        path: "requested-rewards",
        name: "RequestedRewards",
        meta: { requiresAuth: true, breadCrumb: "Premi richiesti" },
        component: () => import("@/views/profile/RequestedRewards.vue")
      },
      {
        path: "create-offer",
        name: "CreateOffer",
        meta: { requiresAuth: true, breadCrumb: "Crea la tua offerta" },
        component: () => import("@/views/profile/RequestedRewards.vue")
      },
      {
        path: "donate",
        name: "Donate",
        meta: { requiresAuth: true, breadCrumb: "Dona i tuoi punti" },
        component: () => import("@/views/profile/Donate.vue")
      },
      {
        path: "invoice",
        name: "Invoice",
        meta: {
          requiresAuth: true,
          breadCrumb: "I miei documenti",
          slug: ["invoice"]
        },
        component: () => import("@/views/profile/Invoice.vue")
      },
      {
        path: "paymentduedate",
        name: "PaymentDueDate",
        meta: {
          requiresAuth: true,
          breadCrumb: "Le mie scadenze",
          slug: ["duedate"]
        },
        component: () => import("@/views/profile/PaymentDueDate.vue")
      }
    ]
  }
];

export default routes;
