<template>
  <v-card class="cart-summary text-right" tile flat v-if="cart.cartId > 0">
    <v-card-actions class="justify-end">
      <v-row no-gutters align="center" justify="center">
        <v-col cols="6" class="px-1" v-if="cart.totalItems > 0">
          <v-btn
            to="/checkout"
            color="primary"
            class="primary"
            x-large
            block
            depressed
            >{{ $t("cart.button.goToPayment") }}</v-btn
          >
        </v-col>
        <v-col cols="6" class="px-1" align="center">
          <div class="d-flex justify-space-between">
            <h1>{{ $t("cartSummary.grossTotal") }}</h1>
            <h1 class="value">{{ $n(cart.totalPrice, "currency") }}</h1>
          </div>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
  <v-card v-else>{{ $t("no-cart") }}</v-card>
</template>
<style lang="scss">
.cart-summary {
  display: grid;
  h1 {
    font-size: 25px;
    line-height: 40px;
    text-transform: uppercase;
  }
  .value {
    min-width: 150px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .v-card__actions {
      padding: 8px 0;
    }
  }
}
</style>
<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "CartSummary",
  props: ["full"],
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    })
  },
  methods: {
    ...mapActions({
      emptyCart: "cart/emptyCart",
      updatedDrawerRight: "app/updatedDrawerRight",
      removeGiftCode: "cart/removeGiftCode"
    }),
    async emptyCartConfirm() {
      if (this.cart.totalItems > 0) {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.emptyCart")
        });
        if (res) {
          this.emptyCart();
        }
      }
    },
    closeDrawer() {
      this.updatedDrawerRight(false);
    },
    removeCode(giftCodeId) {
      this.removeGiftCode(giftCodeId);
    },
    changeService() {},
    changeTimeslot() {},
    changeDelivery() {}
  }
};
</script>
