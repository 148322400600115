const routes = [
  {
    path: "/not-found",
    name: "Not found",
    props: true,
    component: () => import("@/views/NotFound.vue")
  },
  {
    path: "/service-down",
    name: "Service Down",
    props: true,
    component: () => import("@/views/ServiceDown.vue")
  },
  {
    path: "/login",
    name: "login",
    props: true,
    component: () => import("@/views/Login.vue"),
    meta: { registerButton: true }
  }
];

export default routes;
