<template>
  <v-layout column>
    <v-row
      no-gutters
      justify="center"
      align="center"
      class="pa-3 mt-5 text-center"
      >{{ $t("message.checkForMissingDisclaimer") }}</v-row
    >
    <v-row no-gutters justify="center" align="center" class="py-3"
      ><v-btn
        large
        @click="onClick"
        color="primary"
        :to="{
          name: 'ProfileUpdate',
          params: {
            navToPreferences: true
          }
        }"
        >{{ $t("profile.goToProfileBtn") }}</v-btn
      ></v-row
    >
  </v-layout>
</template>

<script>
export default {
  name: "CheckDisclaimerDialog",
  methods: {
    onClick() {
      this.$emit("submit", true);
    }
  }
};
</script>
