<template>
  <v-fab-transition>
    <v-btn
      v-scroll="onScroll"
      v-show="fab"
      fab
      fixed
      center
      bottom
      rounded
      color="primary"
      @click="toTop"
      class="scroll-top"
    >
      <v-icon>$chevronUp</v-icon>
      <span>Torna all'inizio</span>
    </v-btn>
  </v-fab-transition>
</template>
<style lang="scss">
.sso-btn {
  top: 130px;
  z-index: 10;
}
.scroll-top {
  right: 50%;
  transform: translate(50%, 0);
  height: initial !important;
  width: initial !important;
  padding: 8px 16px !important;
  .description {
    display: inline;
  }
}
.is-cordova {
  .scroll-top {
    bottom: 90px;
    right: 50%;
    transform: translate(50%, 0);
    height: initial !important;
    width: initial !important;
    padding: 4px 8px;
    .description {
      display: inline;
    }
  }
}
.is-cordova.keyboard-open {
  .scroll-top {
    bottom: 20px;
  }
}
.is-cordova.keyboard-open.platform-ios {
  .scroll-top {
    bottom: 35px;
  }
}
.is-cordova.platform-ios {
  .scroll-top {
    bottom: 135px;
  }
}
</style>
<script>
export default {
  data() {
    return {
      fab: false
    };
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
    },
    toTop() {
      this.$vuetify.goTo(0);
    }
  }
};
</script>
