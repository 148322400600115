let routes = [
  // {
  //   path: "/coupon",
  //   name: "Coupon",
  //   meta: {
  //     requiresAuth: true,
  //     breadCrumb: "Coupon",
  //     slug: "coupon"
  //   },
  //   component: () => import("@/views/coupon/Coupon.vue")
  // },
  {
    path: "/coupon/discounts",
    name: "Discounts",
    meta: {
      requiresAuth: true,
      breadCrumb: "Discounts",
      slug: "discounts"
    },
    component: () => import("@/views/coupon/Discounts.vue")
  },
  {
    path: "/coupon/personalDiscounts",
    name: "PersonalDiscounts",
    meta: {
      requiresAuth: true,
      breadCrumb: "Sconti per te",
      slug: "personalDiscounts"
    },
    component: () => import("@/views/coupon/PersonalDiscounts.vue")
  },
  {
    path: "/coupon/couponGallery",
    name: "CouponGallery",
    meta: {
      requiresAuth: true,
      breadCrumb: "Galleria Coupon",
      slug: "couponGallery"
    },
    component: () => import("@/views/coupon/CouponGallery.vue")
  },
  {
    path: "/coupon/couponGalleryActive",
    name: "CouponGalleryActive",
    props: { type: "selected" },
    meta: {
      requiresAuth: true,
      breadCrumb: "Galleria Coupon",
      slug: "couponGallery"
    },
    component: () => import("@/views/coupon/CouponGallery.vue")
  },
  {
    path: "/coupon/active",
    name: "ActiveCoupon",
    meta: {
      requiresAuth: true,
      breadCrumb: "Coupon attivi",
      slug: "coupon/active"
    },
    component: () => import("@/views/coupon/CouponActive.vue")
  },
  {
    path: "/coupon/history",
    name: "DiscountHistory",
    meta: {
      requiresAuth: true,
      breadCrumb: "Discount history",
      slug: "coupon/history"
    },
    component: () => import("@/views/coupon/DiscountHistory.vue")
  },
  {
    path: "/coupon/launch-vouchers",
    name: "LaunchVouchers",
    meta: {
      requiresAuth: true,
      breadCrumb: "LaunchVouchers",
      slug: "ricaricati-di-buoni"
    },
    component: () => import("@/views/coupon/LaunchVouchers.vue")
  }
];

export default routes;
