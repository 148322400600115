import { render, staticRenderFns } from "./ShowCard.vue?vue&type=template&id=badc0daa&"
import script from "./ShowCard.vue?vue&type=script&lang=js&"
export * from "./ShowCard.vue?vue&type=script&lang=js&"
import style0 from "./ShowCard.vue?vue&type=style&index=0&id=badc0daa&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\zivan.pesic\\Documents\\esbn-front-vue-abbondanza\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('badc0daa')) {
      api.createRecord('badc0daa', component.options)
    } else {
      api.reload('badc0daa', component.options)
    }
    module.hot.accept("./ShowCard.vue?vue&type=template&id=badc0daa&", function () {
      api.rerender('badc0daa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/buttons/ShowCard.vue"
export default component.exports