const routes = [
  {
    path: "/gala-world/ricette/:pageName",
    name: "ricette",
    meta: {
      breadCrumb: "Ricette"
    },
    component: () => import("@/views/cms/Recipe.vue")
  },
  {
    path: "/gala-world/news/:pageName",
    name: "news",
    meta: {
      breadCrumb: "News"
    },
    component: () => import("@/views/cms/Recipe.vue")
  },
  {
    path: "/gala-world/richiamo_prodotti/:pageName",
    name: "richiamo_prodotti",
    meta: {
      breadCrumb: "Richiamo prodotti"
    },
    component: () => import("@/views/cms/RecalledProductDetails.vue")
  }
];

export default routes;
