<template>
  <v-bottom-navigation grow fixed class="bottom-nav-bar" color="primary">
    <!-- Home -->
    <v-btn :to="{ name: 'Home' }" exact>
      <span>{{ $t("bottom.home") }}</span>
      <v-icon>$home</v-icon>
    </v-btn>
    <!-- Leaflet -->
    <v-btn
      @click="handleLeafletRoute()"
      :class="{
        'v-btn--active':
          $route.path.startsWith('/leaflet') ||
          $route.path.startsWith('/punti-vendita')
      }"
    >
      <span>{{ $t("bottom.leaflet") }}</span>
      <v-icon class="font-weight-semibold">$volantino</v-icon>
    </v-btn>
    <!-- Coupon -->
    <v-btn
      @click="handleLogin('/coupon')"
      :class="{ 'v-btn--active': $route.path.startsWith('/coupon') }"
    >
      <span>{{ $t("bottom.coupon") }}</span>
      <v-icon>$coupon</v-icon>
    </v-btn>
    <!-- Gala World -->
    <v-btn :to="{ name: 'GalaWorld' }">
      <span>{{ $t("bottom.galaWorld") }}</span>
      <img src="/logo/logo_mobile.png" />
    </v-btn>
    <!-- Account -->
    <v-btn :to="{ name: 'Dashboard' }">
      <span>{{ $t("bottom.account") }}</span>
      <v-icon>$profile</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import login from "~/mixins/login";

import { forEachCartItem } from "~/service/ebsn";

import get from "lodash/get";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  mixins: [login],
  props: {
    applicationConfig: { type: Object, required: false }
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions({
      updatedDrawerLeft: "app/updatedDrawerLeft",
      updatedDrawerRight: "app/updatedDrawerRight",
      updatedDrawerLinks: "app/updatedDrawerLinks"
    }),
    async handleLogin(path) {
      if (this.isAuthenticated) {
        this.$router.push(path);
      } else {
        let isLoggedIn = await this.doLogin();
        if (isLoggedIn) {
          this.$router.push(path);
        }
      }
    },
    handleLeafletRoute() {
      if (!this.isAuthenticated && this.getSelectedStore) {
        this.$router.push(`/punti-vendita/${this.getSelectedStore}`);
      } else if (
        this.isAuthenticated &&
        this.user.pdv &&
        this.user.pdv != "0"
      ) {
        this.$router.push(`/punti-vendita/${this.user.pdv}`);
      } else {
        this.$router.push({ name: "Leaflet" });
      }
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated",
      hasFavorites: "cart/hasFavorites",
      getSelectedStore: "custom/getSelectedStore",
      user: "cart/getUser"
    }),
    ...mapState({
      storeDrawerLeft: state => state.app.drawerLeft,
      storeDrawerRight: state => state.app.storeDrawerRight,
      storeDrawerLinks: state => state.app.drawerLinks,
      cart: ({ cart }) => cart.cart
    }),
    totalItems() {
      let total = 0;
      forEachCartItem(this.cart, function(item) {
        total += item.quantity > 0 ? item.quantity : 1;
      });
      return total;
    },
    totalItemsCount() {
      return this.cart.totalItems;
    },
    drawerLeft: {
      get() {
        return this.storeDrawerLeft;
      },
      set(value) {
        this.updatedDrawerLeft(value);
      }
    },
    drawerRight: {
      get() {
        return this.storeDrawerRight;
      },
      set(value) {
        this.updatedDrawerRight(value);
      }
    },
    drawerLinks: {
      get() {
        return this.storeDrawerLinks;
      },
      set(value) {
        this.updatedDrawerLinks(value);
      }
    },
    additionalBtnLink() {
      return get(
        this.applicationConfig,
        "metaData.template_model.ADDITIONAL_BTN_LINK"
      );
    },
    additionalBtnSrc() {
      return get(
        this.applicationConfig,
        "metaData.template_model.ADDITIONAL_BTN_IMG"
      );
    },
    currentUser() {
      if (this.user && this.user.userId) {
        return this.user.userId;
      } else {
        return "noAccount";
      }
    }
  }
};
</script>
<style lang="scss">
.v-main {
  padding-bottom: calc(62px + env(safe-area-inset-top)) !important;
}
.keyboard-open {
  .v-main {
    padding-bottom: calc(80px + env(safe-area-inset-top)) !important;
  }
}
.platform-ios {
  .v-main {
    padding-bottom: calc(110px + env(safe-area-inset-top)) !important;
  }
}
.platform-ios.keyboard-open {
  .v-main {
    padding-bottom: calc(110px + env(safe-area-inset-top)) !important;
  }
}

.bottom-nav-bar {
  height: $bottom-navigation-bar-height;
  color: var(--v-grey-base) !important;
  position: fixed;
  bottom: 0;
  height: calc(
    #{$bottom-navigation-bar-height} + constant(safe-area-inset-top) + 20px
  ) !important;
  padding-top: constant(safe-area-inset-top);
  padding-left: constant(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
  height: calc(
    #{$bottom-navigation-bar-height} + env(safe-area-inset-top)
  ) !important;
  padding-top: env(safe-area-inset-top);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
  .v-btn {
    min-width: auto !important;
    padding: 0 5px;
    font-size: 0.9rem !important;
    .v-btn__content {
      span {
        font-size: 10px;
      }
      .v-icon {
        font-size: 26px;
        padding-bottom: 3px;
      }
      .v-image {
        width: auto;
      }
      img {
        padding: 3px;
        filter: grayscale(1);
        width: 30px;
        height: 30px;
      }
    }
  }
  .v-btn + .v-btn--active {
    .v-btn__content {
      img {
        filter: grayscale(0);
      }
    }
  }
}
.platform-ios {
  .bottom-nav-bar {
    //height: $bottom-navigation-bar-height-ios !important;
    position: fixed;
    bottom: 0;
    padding-top: constant(safe-area-inset-top);
    padding-left: constant(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    max-height: calc(
      #{$bottom-navigation-bar-height-ios} + env(safe-area-inset-top) + 20px
    ) !important;
    padding-top: calc(env(safe-area-inset-top));
    padding-left: env(safe-area-inset-left);
    padding-right: env(safe-area-inset-right);
    padding-bottom: calc(110px + env(safe-area-inset-bottom));
    .v-icon {
      font-size: 30px !important;
    }
    img {
      width: 35px !important;
      height: 35px !important;
    }
  }
}

.keyboard-open {
  .bottom-nav-bar {
    display: none !important;
  }
}
</style>
