import base from "./base";
import wordpress from "./wordpress";
import warehouse from "./warehouse";
import catalog from "./catalog";
import profile from "./profile";
import checkout from "./checkout";
import selfscanning from "./selfscanning";
import coupon from "./coupon";
import cms from "./cms";

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      slug: "home-mobile",
      canonical: "/"
    },
    component: () => import("@/views/catalog/Category.vue")
  },
  ...base,
  ...wordpress,
  ...profile,
  ...coupon,
  ...checkout,
  ...warehouse,
  ...cms,
  ...catalog,
  ...selfscanning
];

export default routes;
